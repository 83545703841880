import * as React from 'react';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import { AccountBalance, AccountBox, ArrowDropDown, Lock, Logout, Notifications, PhoneIphone, Settings, Support } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import Logo from '../assets/nativereplay_standalone_logo.svg';
import { BORDER_WHITE, NATIVE_REPLAY_BLACK, NATIVE_REPLAY_BLACK_2, NATIVE_REPLAY_PRIMARY_COLOR, NATIVE_REPLAY_SECONDAY_COLOR, NATIVE_REPLAY_THIRD_COLOR } from '../Colors';
import { useEffect, useState } from 'react';
import { clearAllUserInfo, getAccountsUserHasAccessTo, getSelectedAccount, setUsersSelectedAccount } from '../api/User';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Avatar, Badge, Button, ButtonBase, Card, FormControl, InputLabel, Link, MenuItem, Popover, Select } from '@mui/material';
import { getNotifications, getUnreadNotificationsCount, markNotificationAsRead } from '../api/NotificationsService';
import { styles } from '../Styles';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(2, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const PrimaryNavigationItems = [
    {
      name: "Replays",
      icon: <PhoneIphone />,
      uri: `/session-replays`,
    },
    {
      name: "Settings",
      icon: <Settings />,
      uri: `/session-replays/settings`,
    },
    {
      name: "Team",
      icon: <AccountBox />,
      uri: `/account`
    },
];

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  
    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }


export default function NativeReplayNavigation(props) {


  const theme = createTheme({
      typography: {
        fontFamily: "Poppins"
      },
      palette: {
        primary: {
          main: NATIVE_REPLAY_THIRD_COLOR,
          light: '#42a5f5',
          dark: '#1565c0',
          contrastText: '#fff',
          mode: 'dark'
      },
        secondary: {
          main: '#1976d2',
          light: '#42a5f5',
          dark: '#1565c0',
          contrastText: '#fff'
      },
    }
    });

    const nav = useNavigate();

    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(getSelectedAccount());
    
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [notifications, setNotifications] = useState([]);

    let notificationsAnchorEl = React.useRef();
    const [notificationOpen, setNotificationOpen] = useState(false);
    
    useEffect(() => {
        // getListOfAccountsUserHasAccessTo((a) => {
        //     setAccounts(a);   
        // });
        //[{"id": 1, "name": "Tenit X"}, {"id": 1001, "name": "Fynd"}]
        setAccounts(getAccountsUserHasAccessTo() || []);
        getUnreadNotificationsCount().then((resp) => {
            setUnreadNotifications(resp.data);
        });
        getNotifications().then((resp) => {
            setNotifications(resp.data);
        });
    }, []);

    useEffect(() => {
      if (accounts.length > 0) {
        const acc = (accounts).find(a => a.id === parseInt(selectedAccount));
        document.title = `Native Replay - ${acc.name}`
      }
    }, [accounts, selectedAccount]);

    const updateSelectedAccount = (a) => {
        setUsersSelectedAccount(a);
        setSelectedAccount(a);
        window.location.reload(false);
    }

    
    return (
      <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', color: "white", fontFamily: "Poppins" }}>
        <AppBar position="fixed" style={{borderColor: BORDER_WHITE}} sx={{ fontFamily: "Poppins", backgroundColor: NATIVE_REPLAY_BLACK, border: "solid", borderWidth: "0px 0px 1px 0px", borderRadius: "0px 0px 0px 0px" }}>
        <div style={{display: "block", textAlign: "left", marginBottom: 0, marginTop: 10, marginLeft: 10}}>
        <img src={Logo} alt="Native Replay Logo." width={75} />
        <FormControl sx={{ marginTop: 2, marginLeft: 1}}>
        <InputLabel sx={{color: "white", fontFamily: "Poppins"}}>Account: </InputLabel>
        <Select 
          MenuProps={{style: {color: "white"}}}
          variant='outlined'
          size="small" 
          sx={{
            color: "white"
          }}
          value={selectedAccount} 
          label="Switch Account"
        >
            {accounts.map(account => <MenuItem sx={{fontFamily: "Poppins"}} value={account.id} onClick={() => updateSelectedAccount(account.id)}><Avatar >{account.name.substring(0,1)}</Avatar> {account.name}</MenuItem>)}
        </Select>
        </FormControl>
        <ButtonBase ref={notificationsAnchorEl} style={{padding: 5}} onClick={() => setNotificationOpen(true)}>
        <Badge badgeContent={unreadNotifications} color="primary" style={{marginLeft: 10, marginTop: 10,
        // right align
        justifyContent: "flex-end",
        }}>
          <Notifications />
        </Badge>
        </ButtonBase>
        <Popover
          open={notificationOpen}
          anchorEl={notificationsAnchorEl.current}
          onClose={() => {setNotificationOpen(false)}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{
            // borderColor: NATIVE_REPLAY_SECONDAY_COLOR, borderWidth: 3, border: "solid"
          }}
        >
          <Box sx={{ p: 1, fontFamily: "Poppins", display: "flex", flexDirection: "column", backgroundColor: NATIVE_REPLAY_BLACK}}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "left",
              backgroundColor: NATIVE_REPLAY_BLACK,
              color: "white",
            }}>
              <h4>Notifications</h4>
              <Button
                onClick={() => {
                  setNotifications(notifications.map(n => {
                    if (!n.isRead) {
                      markNotificationAsRead(n.id);
                    }
                    n.isRead = true;                    
                    return n;
                  }));
                  setUnreadNotifications(0);
                }}
                disabled={unreadNotifications === 0}
              >
                <h6>Mark all as read</h6>
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // width: "100%",
                height: 400,
                overflowY: "scroll",
              }}
            >
            {notifications.map(n =>
            <Link href={n.ctaUrl} underline='none' style={{
              width: "100%",
              textDecoration: "none",
              color: "white"
            }}
            onClick={() => {
              if (!n.isRead) {
                markNotificationAsRead(n.id).then(() => {
                  setUnreadNotifications(unreadNotifications - 1);
                  setNotifications(notifications.map(notif => {
                    if (notif.id === n.id) {
                      notif.isRead = true;
                    }
                    return notif;
                  } 
                  ));

                  // n.isRead = true;
                });
              }}}
            >
              <Badge anchorOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }} badgeContent={n.isRead ? 0 : 1} variant='dot' color="primary" style={{marginLeft: 10, marginTop: 10}}>
              <Card sx={{
                backgroundColor: n.isRead ? NATIVE_REPLAY_BLACK_2 : NATIVE_REPLAY_SECONDAY_COLOR,
                borderColor: n.isRead ? "lightgray" : NATIVE_REPLAY_THIRD_COLOR,
                color: n.isRead ? "lightgray" : "white",
                borderRadius: 10,
                padding: 1.5,
                // margin: 1,
                alignItems: "center",
                justifyContent: "space-between",
                // borderColor: NATIVE_REPLAY_SECONDAY_COLOR, 
                borderWidth: 1, border: "solid"
              }}>
                <h6 style={{fontSize: 18}}>{n.notificationTitle}</h6>
                <div style={{
                  fontSize: 12,
                  color: "lightgray",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%"
                }} 
                dangerouslySetInnerHTML={{__html: n.notificationText}} />
              </Card>
              </Badge>
              </Link>
              )}
            </div>
          </Box>
        </Popover>
        </div>
        
        <div style={{display: "flex", margin: "auto"}}>
        {PrimaryNavigationItems.map(item => <Button variant="outlined" style={{fontFamily: "Poppins", color: item.uri === window.location.pathname ? NATIVE_REPLAY_THIRD_COLOR :  "white", borderColor: NATIVE_REPLAY_THIRD_COLOR, borderWidth: "0px 0px 2px 0px", marginInline: 5, width: 200, fontFamily: "Poppins"}} onClick={() => nav(item.uri)}>{item.name}</Button>)}
        </div>

        </AppBar>
        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: NATIVE_REPLAY_BLACK }}>
          <DrawerHeader />
          {props.children}
        </Box>
      </Box>
      </ThemeProvider>
    );
}