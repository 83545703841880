export const styles = {
    glass: {
      // background: "rgba( 23, 214, 201, 0.25 )",
      background: "rgba( 255, 255, 255, 0.25 )",
      boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
      backdropFilter: "blur( 4px )",
      WebkitBackdropFilter: "blur( 4px )",
      borderRadius: "10px",
      border: "1px solid rgba( 255, 255, 255, 0.18 )",
      color: "white"
    }
  };
  