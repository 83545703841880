import { USER_SELECTED_ACCOUNT } from "./Constants";
import { USER_ACCOUNT_ACCESS, USER_INFO, USER_TOKEN_BASE, USER_TOKEN_SECURE } from "./Constants";

export function getStandardAuthHeaders() {
    return {
        headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
    }
}

export function getUser() {
    return JSON.parse(localStorage.getItem(USER_INFO));
}

export function setUser(user) {
    localStorage.setItem(USER_INFO, JSON.stringify(user));
}

export function clearUser() {
    localStorage.removeItem(USER_INFO);
}

export function getBaseToken() {
    return localStorage.getItem(USER_TOKEN_BASE);
}

export function getSecureToken() {
    return localStorage.getItem(USER_TOKEN_SECURE);
}

export function setTokens(baseToken, secureToken) {
    localStorage.setItem(USER_TOKEN_BASE, baseToken);
    localStorage.setItem(USER_TOKEN_SECURE, secureToken);
}

export function clearTokens() {
    localStorage.removeItem(USER_TOKEN_BASE);
    localStorage.removeItem(USER_TOKEN_SECURE);
}

export function clearAllUserInfo() {
    clearTokens();
    clearUser();
    clearAccountsUserHasAccessTo();
    clearUsersSelectedAccount();
}

export function setAccountsUserHasAccessTo(businessIds) {
    localStorage.setItem(USER_ACCOUNT_ACCESS, JSON.stringify(businessIds));
}

export function getAccountsUserHasAccessTo() {
    return JSON.parse(localStorage.getItem(USER_ACCOUNT_ACCESS));
}

export function clearAccountsUserHasAccessTo() {
    localStorage.removeItem(USER_ACCOUNT_ACCESS);
}

export function setUsersSelectedAccount(accountId) {
    console.trace("Setting usersSelected account", accountId)
    selectedAccount = accountId;
    localStorage.setItem(USER_SELECTED_ACCOUNT, accountId);
}

export function clearUsersSelectedAccount() {
    localStorage.removeItem(USER_SELECTED_ACCOUNT);
}

export function getSelectedAccount() {
    if (selectedAccount) {
        return selectedAccount;
    }
    selectedAccount = localStorage.getItem(USER_SELECTED_ACCOUNT);
    if (selectedAccount) {
        return selectedAccount;
    }
    const accounts = getAccountsUserHasAccessTo()
    if ((accounts || []).length < 1) {
        return undefined;
    }
    selectedAccount = accounts[0].id;
    return selectedAccount;
}

export function isUserLoggedIn() {
    return getUser() && getBaseToken() && getSecureToken();
}

export var selectedAccount;

