import './App.css';
import { Suspense, lazy } from "react";
import { BrowserRouter as Router,  Routes, Route } from "react-router-dom";
import NativeReplayNavigation from './components/NativeReplayNavigation';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { NATIVE_REPLAY_BLACK } from './Colors';


function App() {
const SessionReplaysPage = lazy(() =>
import("./pages/replay/SessionReplaysPage")
);

const SessionReplayViewerPage = lazy(() =>
import("./pages/replay/SessionReplayViewerPage")
);

const SessionReplaysSettingsPage = lazy(() =>
import("./pages/replay/SessionReplaysSettingsPage")
);

const AccountPage = lazy(() =>
import("./pages/AccountPage")
);

const BillingPage = lazy(() =>
import("./pages/BillingPage")
);

const BillingUpgradeSuccessPage = lazy(() =>
import("./pages/BillingPageUpgrade")
);

const AppSumoRedeemPage = lazy(() =>
import("./pages/AppSumoRedeemPage")
);

const ApiTokensPage = lazy(() =>
import("./pages/ApiTokensPage")
);

const LoginPage = lazy(() =>
import("./pages/LoginPage")
);

const SignupPage = lazy(() =>
import("./pages/SignupPage")
);

const SupportPage = lazy(() =>
import("./pages/SupportPage")
);


// react query
const queryClient = new QueryClient();


  return (
    <QueryClientProvider client={queryClient}>
    <Router>
    <div className="App" 
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: NATIVE_REPLAY_BLACK,
      overflow: "hidden"
    }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path={`/session-replays`} element={<NativeReplayNavigation><SessionReplaysPage /></NativeReplayNavigation>} />
          <Route path={`/session-replays/settings`} element={<NativeReplayNavigation><SessionReplaysSettingsPage /></NativeReplayNavigation>} />
          <Route path={`/session-replays/:sessionId`} element={<NativeReplayNavigation><SessionReplayViewerPage /></NativeReplayNavigation>} />
          <Route path={`/api-tokens`} element={<NativeReplayNavigation><ApiTokensPage /></NativeReplayNavigation>} />
          <Route path={`/account`} element={<NativeReplayNavigation><AccountPage /></NativeReplayNavigation>} />
          <Route path={`/support`} element={<NativeReplayNavigation><SupportPage /></NativeReplayNavigation>} />
          <Route path={`/billing`} element={<NativeReplayNavigation><BillingPage /></NativeReplayNavigation>} />
          <Route path={`/billing/upgrade-success/:sessionId`} element={<NativeReplayNavigation><BillingUpgradeSuccessPage /></NativeReplayNavigation>} />
          <Route path={`/billing/app-sumo-redeem`} element={<NativeReplayNavigation><AppSumoRedeemPage /></NativeReplayNavigation>} />

          <Route exact path={`/login`} element={<LoginPage />} />
          <Route exact path={`/signup`} element={<SignupPage />} />
          <Route exact path={""} element={<SignupPage />} />
        </Routes>
        
      </Suspense>
    </div>
    </Router>
    </QueryClientProvider>
  );
}

export default App;
