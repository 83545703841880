export const TENIT_PRIMARY_COLOR = "#ad3cc4"// "#e64889";
export const TENIT_SECONDARY_COLOR = "#e64889";

export const NATIVE_REPLAY_BLACK = "#060e1c"
export const NATIVE_REPLAY_BLACK_2 = "#050a14"
export const NATIVE_REPLAY_PRIMARY_COLOR = "#3574e3";
export const NATIVE_REPLAY_SECONDAY_COLOR = "#26a5d6";
export const NATIVE_REPLAY_THIRD_COLOR = "#17d6c9";

export const BORDER_WHITE = "rgba(255, 255, 255, 0.4)";
