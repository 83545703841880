import axios from "axios";
import { getBaseToken, getSecureToken, getSelectedAccount } from "./User";

const isLocal = false;
export const API_HOST = isLocal ? "http://localhost:8080" : "https://api.tenitx.com";
export const apiCaller = axios.create({
    // withCredentials: true,
    baseURL: API_HOST,
    headers: {
        "tbtc": getBaseToken(),
        "tstc": getSecureToken(),
        // cfJwt: getCloudflareJwtCookie()
    },
    params: {
        accountId: getSelectedAccount()
    }
 });

 apiCaller.interceptors.request.use(function (config) {
    // Do something before request is sent
    const uriBase = config.url.substring(0, config.url.indexOf("/", 1));
    // console.log("uri base", uriBase)
    const maybeHostOverride = localStorage.getItem(`host.override.${uriBase}`);
    if (maybeHostOverride) {
      config.baseURL = maybeHostOverride;
      // console.log("Overriding host of %s request to %s", uriBase, maybeHostOverride)
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });